<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <!-- <debug-btn :item="responseData"></debug-btn> -->
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <hr class="mt-10 mb-10">
          <!-- here -->
          <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
            <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
              <v-skeleton-loader
              class="mx-auto"
              min-width="300"
              type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-10" v-if="pegawai.length > 0">
            <v-col v-for="(peg, i) in pegawai" :key="i" align="center">
              <base-material-card
                class="v-card-profile"
                :avatar="peg.foto"
                style="width:270px; "
              >
                <v-card-text class="text-center" height='fit-content'>
                  <h4 class="display-1 mb-1 teal--text text--darken-2" style="height:auto">
                    <b>{{peg.is_ketua == true ? 'Ketua' : 'Anggota'}}</b>
                  </h4>
                  <h4 class="font-weight-light mb-1 teal--text text--darken-2" style="height:auto">
                    <b>{{peg.pokja}}</b>
                  </h4>
                  <h4 class="font-weight-bold mb-1 teal--text text--darken-2" style="height:auto">
                    <b>Durasi: {{peg.durasi}}</b>
                  </h4>
                  <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:auto">
                    {{peg.nama}}
                  </h4>
                  <h4 class="font-weight-light grey--text mb-2">
                    NIP. {{peg.nip}}
                  </h4>
                  <h4 class="font-weight-light green--text" style="font-size:12px; height:auto">
                    {{peg.jabatan}}
                  </h4>
                  <div class="mt-5" height='fit-content'>
                    <v-chip v-if="peg.is_ketua != true" small label :class="pokjaStatus(peg)" class="ma-1" dark>{{peg.status == null ? 'Menunggu Persetujuan Atasan' : peg.status}}</v-chip>
                  </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <div>
                  <v-btn
                  color="primary"
                  dark
                  rounded
                  class="mr-0"
                  :loading="loadingBtn.accept && loadingEl == i"
                  @click="accept(peg, i)"
                  :small="true"
                  >
                  <span> Terima </span>
                  </v-btn>
                  </div>

                  <div>
                  <v-btn
                  color="error"
                  dark
                  rounded
                  class="mr-0"
                  :loading="loadingBtn.reject && loadingEl == i"
                  @click="reject(peg, i)"
                  :small="true"
                  >
                  <span> Tolak </span>
                  </v-btn>
                  </div>
                </v-card-actions>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
              <v-alert
              color="blue-grey"
              dark
              dense
              icon="mdi-account-group"
              prominent
            >
              Tidak ada data persetujuan anggota pokja yang harus divalidasi
            </v-alert>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import store from '@/store'
import SistemKerjaService from '@/services/SistemKerjaService'
import SKPService from '@/services/SKPService'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
import {local} from '@/store/local'
import { mapState } from 'vuex'
import _ from 'lodash'
import axios from 'axios'

export default {
  data(){
    return{
      valid:true,
      title:'Validasi Anggota Sistem Kerja',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      selectedItem:{},
      tableValues:{},
      loadingBtn:{
        data:false,
        accept:false,
        reject:false,
        dataPegawai:false
      },
      dialogTambahAnggotaPokja: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      kode_skpd: null,
      listStrukturPokja: [],
      pegawai: [],
      id_pokja: null,
      anggota: [],
      nama_pokja: '',
      nip_ketua: '',
      disabled: true,
      fromDateMenu: false,
      endDateMenu: false,
      fromDateVal: null,
      endDateVal: null,
      showItem:false,
      formTambah:{},
      formEdit:{},
      date: new Date(), 
    }
  },
  created(){
    this.year = new Date().getFullYear()-1
    this.triwulan = _g.getTriwulan().toString()
    var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    var base_url = window.location.origin
    // var role = this.current.role
    // var username = this.current.username
    // if(kode_skpd != '4.03.01'){
    //   store.commit('snackbar/setSnack',{message:'Maaf, anda tidak bisa mengakses halaman Sistem Kerja.', color:'warning'})
    //   let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Sistem Kerja.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
  },

  computed:{
    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),
  },

  mounted(){
    this.getDataValidasiAnggotaSistemKerja()
  },

  methods:{
    duration(fromDateVal, endDateVal) {
      var durasi = ''
      if(fromDateVal && endDateVal){
        let startDateTrim = fromDateVal.split("-")
        let startDate = new Date(startDateTrim[0], startDateTrim[1], startDateTrim[2])
        let endDateTrim = endDateVal.split("-")
        let endDate = new Date(endDateTrim[0], endDateTrim[1], endDateTrim[2])

        // Count only working days between mon-fri
        let count = 0;
        const curDate = new Date(startDate.getTime());
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        durasi = count + ' hari kerja'
      }else{
        durasi = '-'
      }
      return durasi
    },
    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getDataValidasiAnggotaSistemKerja(){
      this.loadingBtn.data = true
      this.loadingBtn.dataPegawai = true
      var url = "v1/get-data-validasi-anggota-pokja"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: current.username, api:1}).then(response => {
        let res = response.data
        res.data.forEach(el => {
          el.durasi = this.duration(el.tanggal_mulai, el.tanggal_selesai)
        });
        this.pegawai = res.data
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.data = false
        this.loadingBtn.dataPegawai = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    pokjaStatus(peg){
      let status = peg.status ? peg.status.toLowerCase() : ''
      return{
        red:status === 'menunggu persetujuan atasan' || status === '',
        black:status === 'persetujuan ditolak',
        green: status === 'persetujuan diterima',
        // brown: status === null
        // green:status == 'belum direview'
      }
    },

    accept(peg, idx){
      this.loadingEl = idx
      this.loadingBtn.accept = true
      var url = "v1/terima-validasi-anggota-pokja"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: peg.nip, pokja_id: peg.pokja_id, api:1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataValidasiAnggotaSistemKerja()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.accept = false
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.accept = false
        this.getDataValidasiAnggotaSistemKerja()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    reject(peg, idx){
      this.loadingEl = idx
      this.loadingBtn.reject = true
      var url = "v1/tolak-validasi-anggota-pokja"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {nip: peg.nip, pokja_id: peg.pokja_id, api:1}).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataValidasiAnggotaSistemKerja()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.reject = false
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.reject = false
        this.getDataValidasiAnggotaSistemKerja()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
