import request from '@/utils/request'
import store from '@/store'
import _g from '../global'

const SistemKerjaService = {
  getStrukturPokja,
  addStrukturPokja,
  delStrukturPokja,
  updStrukturPokja,
  setAsRoleKetua
}

function getStrukturPokja(pokja_id){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pokja/get-struktur',
    method: 'post',
    data: {
      nip: current.username,
      pokja_id: pokja_id == null ? "-" : pokja_id
    }
  })
}

function addStrukturPokja(nip_, data){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pokja/add-struktur',
    method: 'post',
    data: {
      nip: nip_,
      pokja_id: data.pokja_id == null ? "-" : data.pokja_id,
      tanggal_mulai: data.tanggal_mulai,
      tanggal_selesai: data.tanggal_selesai
    }
  })
}

function delStrukturPokja(nip_, pokja_id){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pokja/del-struktur',
    method: 'post',
    data: {
      nip: nip_,
      pokja_id: pokja_id == null ? "-" : pokja_id
    }
  })
}

function updStrukturPokja(nip_, pokja_id){
  var current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pokja/upd-struktur',
    method: 'post',
    data: {
      nip: nip_,
      pokja_id: pokja_id == null ? "-" : pokja_id
    }
  })
}

function setAsRoleKetua(nip_, pokja_id){
  return request({
    url: '/pokja/set-role-ketua-tim-pokja',
    method: 'post',
    data: {
      nip: nip_,
      pokja_id: pokja_id
    }
  })
}

export default SistemKerjaService